import { Link } from 'gatsby'
import React from 'react'
import { Instagram, Twitch, Twitter } from 'react-feather'
import Container from './Container'

const Header = ({ headerImage }) => {
    const style = {
        backgroundImage: `url(${headerImage})`,
    }
    const iconSize = 25

    return (
        <header className={headerImage && 'has-image'} style={style}>
            {headerImage &&
                <div className="content">
                    <h1>Janik Urbach</h1>
                    <p>Architekturstudent · Digital Content Creator</p>
                </div>
            }
            <nav>
                <Container>
                    <div>
                        <Link to="/">Home</Link>
                    </div>
                    <div>
                        <a href="https://www.twitch.tv/urbaechsle" target="_blank" rel="noreferrer"><Twitch size={iconSize} /></a>
                        <a href="https://instagram.com/urbaechsle" target="_blank" rel="noreferrer"><Instagram size={iconSize} /></a>
                        <a href="https://twitter.com/urbaechsle" target="_blank" rel="noreferrer"><Twitter size={iconSize} /></a>
                    </div>
                    <div>
                        <Link to="/#about">Über mich</Link>
                    </div>
                </Container>
            </nav>
        </header>
    )
}

export default Header