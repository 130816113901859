import React from 'react'
import Seo from './Seo'
import Header from './Header'
import Footer from './Footer'

import 'normalize.css'
import '../scss/style.scss'

const Layout = ({ pageTitle, headerImage, children }) => (
    <>
        <Seo pageTitle={pageTitle} />
        <Header headerImage={headerImage} />
        <main>
            {children}
        </main>
        <Footer />
    </>
)

export default Layout