import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ pageTitle }) => {
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `
    )

    return (
        <Helmet
            htmlAttributes={{ lang: 'de' }}
            title={pageTitle}
            titleTemplate={`%s - ${site.siteMetadata.title}`}
        >
            <link rel="icon" type="image/vnd.microsoft.icon" href="/favicon.ico" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <meta name="theme-color" content="#1d344e"/>
        </Helmet>
    )
}

export default Seo