import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
    <footer>
        <div>
            &copy; {new Date().getFullYear()} Janik Urbach
        </div>
    </footer>
)

export default Footer